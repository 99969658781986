<template>
  <q-page>
    <div class="layout-padding">
      <div class="row q-col-gutter-sm">
        <div class="col-sm-12">
          <q-card class="bg-white summary bike-hire-card summary-card">
            <q-card-section class="bg-primary title">
              {{ $t('booking.summary') }}
            </q-card-section>
            <q-card-section class="bike-hire-logo">
              <img
                :src="`${summary.logo}`"
                style="max-width:85%"
              >
            </q-card-section>
            <q-card-section class="bike-hire-loc-name">
              {{ $t('going_from') }} <strong>{{ summary.pickup_station.name }}</strong>
            </q-card-section>
            <q-card-section class="bike-hire-loc-date">
              {{ $t('time.at', { time: toMediumDateTime(summary.start_time.date)}) }}
            </q-card-section>
            <q-card-section class="bike-hire-pic">
              <img :src="`${summary.picture}`" style="max-width:50%; margin-left:35%">
            </q-card-section>
            <q-card-section class="bike-hire-price summary">
              <div>{{ $t('net_amount') }}</div><div>{{ summary.order_items[0].net_amount.text }}</div>
              <div>{{ $t('tax.vat') }}</div><div>{{ summary.order_items[0].tax_amount.text }}</div>
              <div>{{ $t('total_cost') }}</div><div>{{ $t('cost.per_day', { num: summary.order_items[0].total.text }) }}</div>
            </q-card-section>
          </q-card>
        </div>
        <div class="col-sm-12">
          <q-banner rounded class="bg-primary">
            <template v-if="!$q.platform.is.mobile" v-slot:avatar>
              <q-icon name="info" />
            </template>
            <div class="text-body2">
              <p v-html="$t('bikehire.brompton.charges_estimate')" />
            </div>
          </q-banner>
        </div>
        <div class="col-sm-12">
          <m-attach-to-booking-request
            :suggested="summary.suggested_journey_name"
            :username="stash.parameters.traveller.value"
            :journey="journey"
            :show-travel-type="organisation && organisation.attributes.travel_policy.bikehire.enabled"
            @change="(val) => $store.dispatch('ondemand/journey', val)"
            @reasonChange="(val) => reason_for = val"
            @labelChange="(val) => labels = val"
            @travelTypeChange="(val) => travelType = val"
          />
        </div>
        <div class="col-sm-12">
          <m-banner
            v-if="search !== 'search'"
            :message="$t('payment_will_be_taken_by', { company: 'Brompton' } )"
            color="primary"
          />
          <m-banner
            v-else
            :message="$t('bikehire.summary_display_only')"
            color="primary"
          />
        </div>
        <payment-buttons
          v-if="search !== 'search'"
          :total-amount="summary.total_amount.amount"
          :requester="stash.parameters.requester"
          :booking-token="summary.booking_token"
          :reason-for="reason_for"
          :journey="journey"
          :labels="labels"
          :supplier-handled="true"
          :is-bike-return="search === 'return'"
          :travel-type="(organisation && organisation.attributes.travel_policy.bikehire.enabled) || !organisation ? travelType : 'Private'"
        />

        <q-btn
          v-else
          class="bg-primary login-btn"
          :label="$t(`bikehire.brompton.show['Log In']`)"
          @click="$router.push({ name: 'bikehire-login-brompton', params: { show: 'Log In' } })"
        />
        <sub
          class="col-12 text-center text-faded"
          style="margin-top: 8px"
          v-html="$t('mobilleo_privacy_and_terms', { privacyURL: partner.privacyURL, termsURL: partner.termsURL })"
        />
      </div>
    </div>
  </q-page>
</template>

<script>
import { mapGetters } from 'vuex'
import store from 'store'
import i18n from 'i18n'
import loading from 'utils/loading'
import { returnSummary, summary as getSummary } from 'api/bikehire'
import paymentButtons from 'pages/payments/payment-method-buttons'
import date from 'utils/date-time'
const { toMediumDateTime } = date
import MAttachToBookingRequest from 'components/MAttachToBookingRequest'
import MBanner from 'components/MBanner'
import { handleErrors } from 'utils/utils'

export default {
  name: 'BikeHireSummary',
  components: {
    paymentButtons,
    MAttachToBookingRequest,
    MBanner
  },
  data () {
    return {
      labels: null,
      reason_for: null,
      travelType: null,
      summary: {
        pickup_station: {
          name: ''
        },
        start_time: {
          date: ''
        },
        order_items: [{
          net_amount: {},
          tax_amount: {},
          total: {}
        }],
        total_amount: {}
      }
    }
  },
  computed: {
    ...mapGetters({
      token: 'ondemand/bookingToken',
      stash: 'ondemand/stash',
      journey: 'ondemand/journey',
      search: 'bikehire/search',
      partner: 'partner',
      organisation: 'organisation'
    })
  },
  async beforeRouteEnter (to, from, next) {
    const token = store.getters['ondemand/bookingToken']
    const partner = store.getters['partner']

    loading.start({
      message: i18n.t('loading.preparing.booking'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      const { data } = store.getters['bikehire.search'] === 'return'
        ? await returnSummary(token)
        : await getSummary(token)

      next(vm => {
        vm.summary = data
        loading.stop()
      })
    } catch (err) {
      handleErrors(err)
      next(false)
      loading.stop()
    }
  },
  methods: {
    getSummary,
    toMediumDateTime
  }
}
</script>

<style lang="stylus" scoped>
@import '../../../styles/ondemand'
.login-btn
  width 100%
  margin-top 10px
</style>
