<template>
  <q-page class="page bg-white column items-center no-wrap">
    <m-group-title :label="label" />
    <bike-hire-results-card
      v-for="location in results"
      :key="location.id"
      :location="location"
      :selected="token === location.search_token"
      @selected="selectBike(location.search_token)"
    />
    <q-footer
      v-if="$q.platform.is.mobile"
      class="bg-white"
      align="center"
    >
      <q-btn
        :label="$t('continue')"
        color="primary"
        @click="$router.push({name: 'ondemand-bikehire-summary'})"
      />
    </q-footer>
  </q-page>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import { MGroupTitle } from 'components/'
import store from 'store'
import i18n from 'i18n'
import { availability, returnSearch } from 'api/bikehire'
import date from 'utils/date-time'
import loading from 'utils/loading'
import { handleErrors } from 'utils/utils'

import bikeHireResultsCard from './results-card'

export default {
  name: 'BikeHireResults',
  components: {
    bikeHireResultsCard,
    MGroupTitle
  },
  data () {
    return {
      token: null,
      label: this.$t('bikehire.closest_docks')
    }
  },
  computed: {
    ...mapGetters({
      results: 'ondemand/selectionList',
      booking_token: 'ondemand/bookingToken'
    })
  },
  async beforeRouteEnter (to, from, next) {
    const partner = store.getters['partner']
    const { parameters } = store.getters['ondemand/stash']
    const search = store.getters['bikehire/search']
    const bikehireStatus = store.getters['bikehire/status']
    const { place_id, value } = parameters.origin

    const params = {}

    params.origin = place_id || value
    params.start_time = date.toCivilDateTime(parameters.depart)

    const bookingRef = search === 'return' ? bikehireStatus.brompton.booking_reference : null

    loading.start({
      message: i18n.t('loading.searching.docks'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      const { data: { options } } = search === 'return'
        ? await returnSearch({ ...params, end_time: params.start_time }, bookingRef)
        : await availability(params)

      const token = options[0].search_token

      store.dispatch('ondemand/booking_token', token)
      await store.dispatch('ondemand/stashProp', { path: 'selectionList', value: options })
      next(vm => {
        vm.token = token
        loading.stop()
      })
    } catch (err) {
      handleErrors(err)
      next(false)
      loading.stop()
    }
  },
  methods: {
    selectBike (token) {
      this.token = token
      this.$store.dispatch('ondemand/booking_token', token)
    }
  }
}
</script>

<style scoped lang="stylus">
@import "../../../styles/ondemand"
</style>
